
import { Component, Vue, Prop } from 'vue-property-decorator'
import { TableDefinition } from '../types'
import { VDataTable } from 'vuetify/lib'
import TableSummary from './TableSummary.vue'

@Component({ components: { VDataTable, TableSummary } })
export default class DataTable extends Vue {
  @Prop() data!: TableDefinition
  @Prop({ type: Boolean, required: false, default: false }) showExpand!: boolean
  @Prop({ required: false }) expanded!: any[]

  render (createElement: Vue.CreateElement) {
    const scopedSlots: any = { ...this.$scopedSlots }
    for (const col of this.data.columns) {
      if (col.type === 'image') {
        scopedSlots['item.' + col.key] = ({ item }: {item: any}) => createElement('img', {
          attrs: {
            src: item[col.key]
          },
          class: { 'item-thumbnail': true }
        })
      } else if (col.type === 'integer') {
        scopedSlots['item.' + col.key] = ({ item }: {item: any}) => {
          const value = item[col.key]
          return col.formatting !== false ? value.toLocaleString() : value
        }
      } else if (col.type === 'datetime') {
        scopedSlots['item.' + col.key] = ({ item }: {item: any}) => new Date(item[col.key]).toLocaleString()
      }
    }

    return createElement(
      'div', [
        createElement(
          'v-data-table', {
            props: {
              title: 'Data Table',
              showExpand: this.showExpand,
              items: this.data.rows,
              headers: this.headers,
              hideDefaultFooter: this.data.pagination_enabled === false,
              disablePagination: this.data.pagination_enabled === false,
              expanded: this.expanded
            },
            scopedSlots
          }
        ),
        createElement(
          'table-summary', {
            props: { items: this.data.summary || [] }
          }
        )
      ]
    )
  }

  get headers () {
    return this.data.columns.map(col => (
      {
        text: col.label,
        value: col.key,
        sortable: true

      }
    ))
  }
}
