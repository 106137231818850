import { ActionContext } from 'vuex'

import { RootState, UserState } from '../types'
import rest from '@/rest'
import { User, UserNew, UserPatch, UsersByState } from '@/types/users'

const state: UserState = {
  current: null,
  currentLoading: true,
  listLoading: false,
  editLoading: false,
  users: {}
}

type UserActionContext = ActionContext<UserState, RootState >

const mutations = {
  SET_CURRENT_LOADING (state: UserState, loading: boolean) {
    state.currentLoading = loading
  },
  SET_LIST_LOADING (state: UserState, loading: boolean) {
    state.listLoading = loading
  },
  SET_LIST (state: UserState, data: User[]) {
    state.users = Object.fromEntries(data.map(item => [item.id, item]))
  },
  SET_CURRENT_USER (state: UserState, data: User) {
    state.current = data
  },
  EDIT_LIST_ITEM (state: UserState, data: UserPatch) {
    const item = state.users[data.id]
    if (!item) {
      throw new Error('Attempt to edit user not in list')
    }
    state.users[data.id] = { ...item, ...data }
  },
  SET_EDIT_LOADING (state: UserState, loading: boolean) {
    state.editLoading = loading
  }
}

const actions = {
  async LOAD_CURRENT_USER (ctx: UserActionContext) {
    ctx.commit('SET_CURRENT_LOADING', true)
    try {
      const data = await rest.users.getCurrentUser()
      ctx.commit('SET_CURRENT_USER', data)
    } finally {
      ctx.commit('SET_CURRENT_LOADING', false)
    }
  },
  async LOAD_LIST (ctx: UserActionContext) {
    ctx.commit('SET_LIST_LOADING', true)
    try {
      const data = await rest.users.getAllUsers()
      ctx.commit('SET_LIST', data)
    } finally {
      ctx.commit('SET_LIST_LOADING', false)
    }
  },
  async CREATE_USER (ctx: UserActionContext, user: UserNew): Promise<void> {
    ctx.commit('SET_EDIT_LOADING', true)
    try {
      await rest.users.createUser(user)
    } finally {
      ctx.commit('SET_EDIT_LOADING', false)
    }
  },
  async UPDATE_USER_ROLE (ctx: UserActionContext, data: {id: string; role: string}): Promise<void> {
    ctx.commit('SET_EDIT_LOADING', true)
    try {
      await rest.users.updateUserRole(data.id, data.role)
      ctx.commit('EDIT_LIST_ITEM', data)
    } finally {
      ctx.commit('SET_EDIT_LOADING', false)
    }
  },
  async UPDATE_USER_STATE (ctx: UserActionContext, data: {id: string; state: string}): Promise<void> {
    ctx.commit('SET_EDIT_LOADING', true)
    try {
      await rest.users.updateUserState(data.id, data.state)
      ctx.commit('EDIT_LIST_ITEM', data)
    } finally {
      ctx.commit('SET_EDIT_LOADING', false)
    }

    const userName = ctx.getters.getUser(data.id).display_name
    const stateMsg = data.state === 'approved' ? 'enabled' : 'disabled'
    ctx.dispatch(
      'snackbar/SHOW_MESSAGE',
      { content: `${userName} ${stateMsg}!`, color: 'success' },
      { root: true }
    )
  },
  async UPDATE_USER_PASSWORD (ctx: UserActionContext, data: {password: string; token: string}): Promise<void> {
    ctx.commit('SET_EDIT_LOADING', true)
    try {
      await rest.users.updateUserPassword(data.password, data.token)
    } finally {
      ctx.commit('SET_EDIT_LOADING', false)
    }
  },
  async RESET_USER_PASSWORD (ctx: UserActionContext, email: string): Promise<void> {
    ctx.commit('SET_EDIT_LOADING', true)
    try {
      await rest.users.resetUserPassword(email)
    } finally {
      ctx.commit('SET_EDIT_LOADING', false)
    }
  },
  async SEND_VERIFICATION_EMAIL (ctx: UserActionContext, email: string): Promise<void> {
    ctx.commit('SET_EDIT_LOADING', true)
    try {
      await rest.users.sendVerificationEmail(email)
    } finally {
      ctx.commit('SET_EDIT_LOADING', false)
    }
  },
  async VERIFY_EMAIL (ctx: UserActionContext, p: { email: string; token: string }): Promise<void> {
    ctx.commit('SET_EDIT_LOADING', true)
    try {
      await rest.users.verifyEmail(p.email, p.token)
    } finally {
      ctx.commit('SET_EDIT_LOADING', false)
    }
  }

}

const getters = {
  list: (state: UserState) => Object.values(state.users),
  current: (state: UserState) => state.current,
  currentLoading: (state: UserState) => state.currentLoading,
  listLoading: (state: UserState) => state.listLoading,
  editLoading: (state: UserState) => state.editLoading,
  getUser: (state: UserState) => (id: string) => state.users[id],
  byState: (state: UserState): UsersByState => {
    const groups: UsersByState = {
      pending: [],
      approved: [],
      rejected: []
    }
    for (const user of Object.values(state.users)) {
      groups[user.state].push(user)
    }
    return groups
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
