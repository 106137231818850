
import { Component, Vue } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import { UserNew } from '../types/users'

@Component
export default class Register extends Vue {
  @Getter('users/editLoading') loading!: boolean
  @Action('users/CREATE_USER') createUser!: (user: UserNew) => Promise<void>

  userName = ''
  email = ''
  password = ''
  isSubmit = false
  isLoading = false

  async submit () {
    await this.createUser({
      display_name: this.userName,
      password: this.password,
      email: this.email
    })
    // with query param email
    this.$router.replace({
      name: 'email-verification', query: { email: this.email, newUser: 'true' }
    })
    this.isSubmit = true
  }
}
