export class RestError extends Error {
  response: Response
  url: string
  status: number | null
  json: any | null
  text: string| null
  simpleMessage: string
  errorKey: string | null

  constructor (response: Response, json: any | null, text: string | null) {
    // const msg = ((json || {}).detail || text
    super(`${response.status} - ${response.url}: ${text}`)
    this.name = 'RestError'
    this.url = response.url
    this.json = json
    this.text = text
    this.status = response.status
    this.response = response
    if (this.json) {
      this.simpleMessage = this.json.detail || this.json
      this.errorKey = this.json.detail?.key || null
    } else {
      this.simpleMessage = this.response.statusText
    }
  }

  toString (): string {
    if (this.json) {
      const detail = this.json.detail
      if (!detail) {
        return this.text || `${this.response.status}: ${this.response.statusText}`
      } else if (Array.isArray(detail)) {
        return detail.map((msg: any) => `${msg.loc.join(', ')}: ${msg.msg}`)
          .join(',')
      } else if (typeof detail === 'object') {
        if (detail.msg) {
          return detail.msg
        } else {
          return JSON.stringify(detail)
        }
      } else {
        return detail
      }
    }
    return `${this.response.status}: ${this.response.statusText}`
  }
}

export class Unauthorized extends RestError {
  constructor (response: Response, json: any | null, text: string | null) {
    super(response, json, text)
    this.name = 'Unauthorized'
  }
}

export async function errorFromResponse (response: Response): Promise<RestError | null> {
  if (response.status && response.status < 400) {
    return null
  }
  const text = await response.text()
  let json: string | null = null
  try {
    json = JSON.parse(text)
  } catch (err) {}
  let Klass: any
  if (response.status === 401) {
    Klass = Unauthorized
  } else {
    Klass = RestError
  }
  return new Klass(response, json, text)
}
