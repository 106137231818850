
import { defineComponent, ref } from 'vue'
import { useStore } from '@/composables/use-store'
import { RestError } from '@/rest/errors'

// vue2
export default defineComponent({
  props: {
    email: {
      type: String,
      required: true
    },
    token: {
      type: String,
      required: false
    },
    newUser: {
      type: Boolean,
      default: false,
      required: false
    }
  },

  setup (props) {
    const store = useStore()
    const inputEmail = ref<string>(props.email)
    const verificationCode = ref<string>(props.token || '')
    const submittedRequest = ref<boolean>(false)
    const submittedCode = ref<boolean>(false)
    const loading = ref<boolean>(false)
    const invalidToken = ref<boolean>(false)
    submittedRequest.value = !!(props.token && props.email) || props.newUser

    const submitMail = async () => {
      loading.value = true
      verificationCode.value = ''
      invalidToken.value = false
      try {
        await store.dispatch('users/SEND_VERIFICATION_EMAIL', inputEmail.value)
      } finally {
        loading.value = false
      }

      submittedRequest.value = true
    }

    const submitCode = async () => {
      loading.value = true
      try {
        await store.dispatch('users/VERIFY_EMAIL', {
          email: inputEmail.value,
          token: verificationCode.value
        })
        submittedCode.value = true
      } catch (error) {
        if ((error as RestError).errorKey === 'invalid-email-verification-token') {
          invalidToken.value = true
          submittedRequest.value = false
        } else {
          throw (error)
        }
      } finally {
        loading.value = false
      }
    }

    return {
      submitMail,
      inputEmail,
      submittedRequest,
      verificationCode,
      submitCode,
      submittedCode,
      loading,
      invalidToken
    }
  }

})
