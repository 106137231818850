
import { Component, Vue, Prop } from 'vue-property-decorator'
import { Action } from 'vuex-class'
import { MediaFile } from '@/types/uploads'
import MediaItemCard from '@/components/MediaItemCard.vue'

@Component({ components: { MediaItemCard } })
export default class MediaFileCard extends Vue {
  @Prop() fileInfo!: MediaFile
  @Prop({ type: String, default: null }) to!: string | null
  @Action('uploads/DELETE') deleteFile!: (p: string) => Promise<void>

  get subtitle () {
    const prefix = this.fileInfo.type + '/' + this.fileInfo.filename.split('.').slice(-1)[0]
    return `${prefix} - ${this.fileInfo.size_human}`
  }

  get createdByName (): string | null {
    return this.fileInfo.created_by?.display_name || null
  }

  get thumbnail () {
    switch (this.fileInfo.type) {
      case 'video':
        return 'mdi-video'
      case 'image':
        return this.fileInfo.url
      case 'text':
        return 'mdi-file-document'
      case 'sheet':
        return 'mdi-file-excel'
      case 'audio':
        return 'mdi-music'
      default:
        return 'mdi-file'
    }
  }

  async onDelete () {
    const msg = `Are you sure you want to delete file "${this.fileInfo.filename}"?`
    if (confirm(msg)) {
      await this.deleteFile(this.fileInfo.filename)
    }
  }
}
